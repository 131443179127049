'use client'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import Image from 'next/image'
import { getCookie, setCookie } from 'helpers/cookies'
import {
  jobCountSourceTracker,
  setJobViewCookiesTracker,
  viewJobSourceTracker
} from 'components/ReportTracker/util'

import styles from 'app/index.module.scss'
import { reportTrackerJobCounts } from 'components/ReportTracker/JobCountTracker'
import CircularProgress from './CircularProgress'
import { fetchJobsH5Page } from 'app/(main-page)/services'
import { getServerLanguageCode } from 'helpers/country'

const JobsCard = (props: {
  langKey: string
  recommend_jobs: any
  device_udid: string
}) => {

  const { langKey, recommend_jobs, device_udid } = props
  // console.log('recommend_jobs', { recommend_jobs, langKey })

  const router = useRouter()
  const sessionid = getCookie('sessionid')
  const [current, setCurrent] = useState<number>(1)
  const [jobs, setJobs] = useState<Array<any>>(recommend_jobs.jobs || [])
  const [hasMore, setHasMore] = useState<boolean>(recommend_jobs.has_more)
  const [loading, setLoading] = useState<boolean>(false)

  const pageParams = {
    size: 20,
    page: 1,
    source: 'web',
    lang: getServerLanguageCode(langKey),
    device_udid: device_udid
  }

  function trackerJobCounts(jobs) {
    if (!jobs?.length) return
    const jobsIds = jobs.map((item) => item?.job_id).join(',')
    reportTrackerJobCounts({ source: jobCountSourceTracker.home, job_ids: jobsIds })
  }

  useEffect(() => {
    const jobs = recommend_jobs.jobs || []
    trackerJobCounts(jobs)
  }, [recommend_jobs?.jobs])

  const getList = async (params: {
    size: number
    page: number
    source: string
    lang: string
    device_udid: string
    job_preferences_id?: number
  }) => {
    setLoading(true)
    fetchJobsH5Page(params).then((res) => {
      const data = res?.data?.data
      // console.log('data', data)
      if (res?.data?.code == 0) {
        setJobs([...jobs, ...data.jobs])
        setHasMore(data.has_more)
        trackerJobCounts(data.jobs || [])
      } else {
        setCurrent(params.page - 1)
      }
    })
      .catch((err) => {
        setCurrent(params.page - 1)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      const page = current + 1
      setCurrent(page)
      const job_preferences_id = recommend_jobs?.job_preferences_id
      getList({ ...pageParams, page, job_preferences_id })
    }
  }

  const handleAddJobView = async ({
    url,
    reco_from,
    query_id
  }: {
    url: string
    reco_from?: string
    query_id?: string
  }
  ) => {
    setJobViewCookiesTracker(setCookie, {
      source: viewJobSourceTracker.home,
      reco_from: reco_from ? reco_from : null,
      query_id: query_id ? query_id : null
    })

    goToJobDetail(url)
  }

  const goToJobDetail = (url: string) => {
    router.push('/' + langKey + url)
  }

  const getStarted = () => {
    router.push('/' + langKey + '/get-started')
  }

  const getPreference = () => {
    router.push('/' + langKey + '/manage-profile?tab=job-preferences')
  }

  const tipsFun = (
    <p className={styles.tips}>
      {sessionid ? (
        <>
          {recommend_jobs.recommend_text}{' '}
          <span className={styles.preference} onClick={getPreference}>
            {recommend_jobs.recommend_highlight_text}
          </span>
        </>
      ) : (
        <>
          <span style={{ marginTop: '12px' }}>{recommend_jobs.recommend_text}</span>
          <span className={styles.started} onClick={getStarted}>
            {recommend_jobs.login_text}
          </span>
        </>
      )}
    </p>
  )

  return (
    <>
      <div id='getStartedContainer'>{tipsFun}</div>
      {jobs?.map((item, index) => {
        const {
          job_id,
          company_name,
          job_url,
          location,
          job_title,
          reco_from,
          query_id,
          job_tags = [],
          recruiter = {},
          salary_info = {}
        } = item || {}

        return (
          <div
            className={styles.jobCard}
            key={`${job_id}-${index}`}
            onClick={() => handleAddJobView({ url: job_url, reco_from, query_id })}
          >
            <div className={styles.name}>
              <p className={styles.jobName}>{job_title}</p>
              <span className={styles.salary}>
                {salary_info?.text ? `${salary_info?.text}` : null}
                {salary_info?.type_text ? `[${salary_info?.type_text}]` : null}
              </span>
            </div>
            <p className={styles.company}>{company_name}</p>
            {
              job_tags?.map((item, index) => {
                return (
                  <span className={styles.tag} key={index}>{item}</span>
                )
              })
            }

            <div className={styles.contact}>
              <div className={styles.hrFullInfo}>
                <div
                  className={`${styles.avator}  ${recruiter.is_online ? styles.avator2 : ''}`}
                >
                  <Image src={recruiter.avatar} alt={recruiter.full_name} width={20} height={20} />
                </div>
                <span>{[recruiter.full_name, recruiter.job_title].filter((a) => a).join(' · ')}</span>
              </div>
              {location ? <span className={styles.location}>{location}</span> : null}
            </div>
          </div>
        )
      })}

      <div className={styles.load}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }} >
            <CircularProgress />
          </div>
        ) : !hasMore ? (
          recommend_jobs?.no_more
        ) : (
          <div className={styles.loadMore} onClick={handleLoadMore}>
            {recommend_jobs?.more_text}
          </div>
        )}
      </div>
    </>
  )
}
export default JobsCard
