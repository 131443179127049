import configuredAxios from "helpers/configuredAxios"
import { cache } from 'react'

export const fetchHomePageSEO = cache(async ({ lang }) => {
  const axios = await configuredAxios('apiGo')
  return axios.get(`/web/home_page_seo?lang=${lang}`)
})

export const fetchMainFunction = async ({ lang }) => {
  const axios = await configuredAxios('apiGo')
  return axios.get(`/web/main_function?lang=${lang}`)
}

export const fetchHomePage = async ({ lang, device_udid }) => {
  const axios = await configuredAxios('apiGo')
  const params = { lang, device_udid }
  return axios.get(`/web/home_page`, { params })
}

export const fetchHomeH5Page = async ({ lang, device_udid }) => {
  const axios = await configuredAxios('apiGo')
  const params = { lang, device_udid }
  return axios.get(`/web/home_page_h5`, { params })
}

export const fetchJobsH5Page = async ({
  lang,
  device_udid,
  size,
  page,
  job_preferences_id
}: {
  lang: string
  device_udid: string
  size: number
  page: number
  job_preferences_id?: number
}
) => {
  const axios = await configuredAxios('apiGo')
  const params = { lang, device_udid, size, page, job_preferences_id }
  return axios.get(`/web/home_page_h5_jobs`, { params: params })
}