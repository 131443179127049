'use client'
import { getDeviceNameTracker, setCompanyViewCookiesTracker, viewCompanySourceTracker } from "components/ReportTracker/util"
import { setCookie } from "helpers/cookies"
import { getCountryId } from "helpers/country"
import { getDeviceUuid } from "helpers/guest"
import { addEventTag } from "helpers/utilities"

const companiesTags = ["hp_top_companies_card_click", "hp_top_companies_job_card_click", "hp_top_companies_card_more_click"]

const LinkToMoreJob = props => {
  const { className, isMobile, langKey, companyUrl, company, children } = props
  const sendViewCompany = async (item, url) => {
    const device_udid = await getDeviceUuid()
    const country_id = getCountryId()
    const params = {
      id: company?.id,
      payload: {
        source: viewCompanySourceTracker.home,
        device: getDeviceNameTracker(isMobile),
        country_id: country_id || '',
        device_udid
      }
    }
    setCompanyViewCookiesTracker(setCookie, params)
    window.location.href = url
  }
  return <div
    className={className}
    onClick={() => {
      sendViewCompany(company, `/${langKey}${companyUrl}/jobs`)
      addEventTag(companiesTags[2])
    }}
  >{children}</div>
}

export default LinkToMoreJob