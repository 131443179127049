'use client'
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react'
import { hoverable } from 'components/highLevel/hoverable'
import { usePageGrouped } from './hooks'
import { LocationContext } from 'app/components/providers/locationProvier'
import styles from 'app/index.module.scss'
import { buildQuery } from 'app/(main-page)/helper'
import Link from 'components/Link'
import classNames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import { ArrowForwardIosIcon } from 'app/components/MuiIcons'
import { ArrowBackIosIcon } from 'app/components/MuiIcons'
import { addEventTag } from 'helpers/utilities'

const getDisplayWidth = (ref, parentRef) => {
  function getVisibleWidthPercentage(element, parentElement) {
    const parentRect = parentElement.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const visibleLeft = parentRect.left
    const visibleRight = parentRect.right

    if (elementRect.right < visibleRight) {
      // fully visible
      return { visible: true, visibleWidth: elementRect.width }
    }

    const visibleWidth = visibleRight - elementRect.left;

    return {
      visible: false,
      visibleWidth
    }
  }

  return getVisibleWidthPercentage(ref, parentRef)
}
const CalculateLink = ({ href, subTitle, onMouseOver, onClick, parentRef, bindRef, style }) => {
  return <Link
    href={href}
    style={style}
  >
    <div
      ref={bindRef}
      title={subTitle}
      className={styles.mainTitleSub}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      {subTitle}
    </div>
  </Link>
}

const MainItem = hoverable((props: any) => {
  const {
    isHover,
    setHoverData,
    data,
    onMouseEnter,
    onMouseLeave,
    setHoverTitle,
    hoverTitle,
  } = props

  const { location } = useContext(LocationContext)
  const ref = useRef(null)
  const childRef = useRef({})
  const [childStyle, setChildStyle] = useState([])
  const title = data.simple_title || data.value

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    let childRefObj = childRef.current
    let newStyle = Object.keys(childRefObj).map(key => {
      const { visibleWidth, visible } = getDisplayWidth(childRefObj[key], ref.current)
      if (visible) {
        return {}
      }
      if (visibleWidth < 50) {
        return { display: 'none' }
      } else {
        return {
          display: 'flex',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      }
    })
    setChildStyle(newStyle)
  }, []);

  useLayoutEffect(() => {
    if (isHover) {
      setHoverData(data.sub_function_list)
      setHoverTitle(title)
    }
  }, [isHover])

  return (
    <div
      className={classNames({
        [styles.mainItem]: true,
        [styles.isHover]: isHover || hoverTitle === title
      })}
      title={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.mainTitle}>
        <div className={styles.mainTitleFirst}>{title}</div>
        <div className={styles.subContainer} style={{ overflow: 'hidden' }} ref={ref}>
          {data.sub_title_list.map((subTitle, index) => {
            return (
              <CalculateLink
                parentRef={ref}
                style={childStyle[index]}
                bindRef={ref => (childRef.current[index] = ref)}
                key={subTitle.id + index}
                href={'/' + props.langKey + buildQuery(location?.seo_value, subTitle.value)}
                onMouseOver={() => addEventTag("hp_job_function_hover")}
                onClick={() => addEventTag("hp_job_function_click")}
                subTitle={subTitle.value}
              />
            )
          })}
        </div>
        <span style={{ display: 'inline-flex', marginLeft: 12, height: 12, width: 12, flexShrink: 0 }}>
          <ArrowForwardIosIcon className={styles.more} />
        </span>
      </div>
    </div>
  )
})

const SectionItem = (props: any) => {
  const { data }: any = props
  return (
    <div className={styles.sectionItems} key={data?.id + data['seo_value']}>
      <label className={styles.sectionName}>{data.value}</label>
      <div className={styles.subItems}>
        {data?.job_titles.map((item, index) => (
          <SubItem data={item} langKey={props.langKey} key={item.value + index} />
        ))}
      </div>
    </div>
  )
}

const SubItem = hoverable((props: any) => {
  const { location } = useContext(LocationContext)
  const { data } = props
  return (
    <Link
      className={styles.subItem}
      href={'/' + props.langKey + buildQuery(location?.seo_value, data?.value)}
    >
      <Tooltip title={data.value} placement='top-start'>
        <div className={styles.linkText}> {data.value}</div>
      </Tooltip>
    </Link>
  )
}
)

const FunctionFilter: React.FC<{
  list: any[]
  langKey: string
}> = hoverable((props: any) => {
  const { list, isHover, langKey, ...rest } = props
  const [hoverTitle, setHoverTitle] = useState('')

  const { currentPage, totalPages, pageDatas, nextEnable, preEnable, onNext, onPre } =
    usePageGrouped(list)

  // console.log('list', { list, pageDatas })

  const [hoverData, setHoverData] = useState<any[] | null>(null)
  useEffect(() => {
    if (!isHover && hoverData) {
      setHoverData(null)
      setHoverTitle('')
    }
  }, [isHover, hoverData])

  // console.log('hoverData', { hoverData, hoverTitle })

  return (
    <div className={styles.functionFilter} {...rest}>
      <div className={styles.mainItems}>
        {pageDatas.map((main, index) => {
          return (
            <MainItem
              key={main.id + index}
              hoverTitle={hoverTitle}
              setHoverData={setHoverData}
              data={main}
              setHoverTitle={setHoverTitle}
              langKey={langKey}
            />
          )
        })}

        <div className={styles.pagination}>
          <label>
            {currentPage}/<span>{totalPages}</span>
          </label>
          <div>
            <button
              type='button'
              disabled={!preEnable}
              className={styles.prePage}
              style={{ paddingLeft: '6px' }}
              onClick={onPre}
            >
              <ArrowBackIosIcon className={styles.icon} />
            </button>
            <button type='button' disabled={!nextEnable} onClick={onNext}>
              <ArrowForwardIosIcon className={styles.icon} />
            </button>
          </div>
        </div>
      </div>
      {hoverData && (
        <div className={styles.sectionContainer}>
          {(hoverData || []).map((sectionData) => {
            return (
              <SectionItem langKey={langKey} data={sectionData} key={sectionData.id} />
            )
          })}
        </div>
      )}
    </div>
  )
}
)

export default FunctionFilter
